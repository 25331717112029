import { KeycloakConfig } from 'keycloak-angular';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';

const WlBaseData = {
  displayName: 'MULTIPLY BANK', //
  urlName: 'multiplybank', // base name for urls
  folderNameBase: 'multiplybank', // internal folder name
  storageUrlBase: './assets/whitelabel/multiplybank/images', // folder with images
  uuidWl: 'b82d33fd-7177-36b3-a16c-0a4955a0439b', // uuid wl in database
  companyData: {
    name: 'MULTIPLY INSTITUIÇÃO DE PAGAMENTOS LTDA',
    city: 'Rio de Janeiro',
    state: 'RJ',
    address: 'Rua Pareto',
    zipCode: '20550-120',
    cnpj: '43.199.505/0001-30',
    site: 'www.multiplybank.com.br',
    emailSac: 'leonardo.vargas@groupmultiply.com',
  },
  introImages: 4, // how many intro images (count only the same size )
  bannerSquareImages: 1, // how many banner-square images (count only the same size)
  bannerFullImages: 3, // how many banner-full images (count only the same size)
  colors: {
    primary: '#012340',
    secondary: '#045fb0',
    tertiary: '#4a9ee8',
    avatar: '#ffffff', // color of avatar and arrow in dashboard page
  },
};

const keycloakConfig: KeycloakConfig = {
  url: `https://accounts.${WlBaseData.urlName}.secure.srv.br/auth/`,
  realm: WlBaseData.urlName,
  clientId: 'wl-webbanking',
};

const wlThemeParameterization: LayoutConfigModel = {
  wlTheme: {
    wlName: WlBaseData.displayName,
    realm: WlBaseData.urlName,
    theme: 'Sentinel',
    favicon: `${WlBaseData.storageUrlBase}/favicon.png`,
    optionalPages: true,
    company: WlBaseData.companyData,
    application: [
      {
        label: 'Portal de Venda (POS)',
        img: './assets/images/notifications/grid/supermarket.svg',
        url: `https://sales.${WlBaseData.urlName}.secure.srv.br`,
        blank: true,
        roles: ['sales_access'],
        isVisible: true,
      },
      {
        label: 'Mentor',
        img: './assets/images/notifications/grid/maquininha-ec.svg',
        url: `https://mentor.${WlBaseData.urlName}.secure.srv.br`,
        blank: true,
        roles: [
          'mentor_assistant',
          'mentor_comercial_admin',
          'mentor_comercial_operator',
          'mentor_logistic_admin',
          'mentor_logistic_operator',
          'mentor_partner',
          'mentor_super_admin',
        ],
        isVisible: true,
      },
      {
        label: 'Backoffice',
        img: './assets/images/notifications/grid/backoffice.svg',
        url: `https://bo.${WlBaseData.urlName}.secure.srv.br`,
        blank: true,
        roles: ['bo-access'],
        isVisible: true,
      },
    ],
    intro: {
      brand: {
        logo: `${WlBaseData.storageUrlBase}/brand-monochrome-large.svg`,
      },
      background: {
        image: `${WlBaseData.storageUrlBase}/background-horizontal-large.svg`,
        color: 'transparent',
      },
      text: {
        color: '#ffffff',
      },
      button: {
        background: {
          color: {
            primary: WlBaseData.colors.primary,
            secondary: WlBaseData.colors.tertiary,
          },
          hover: {
            primary: WlBaseData.colors.primary,
            secondary: WlBaseData.colors.secondary,
          },
        },
        text: {
          color: {
            primary: '#ffffff',
            secondary: '#ffffff',
          },
        },
        border: {
          color: {
            primary: '#ffffff',
            secondary: WlBaseData.colors.tertiary,
          },
        },
      },
      social: {
        color: '#ffffff',
      },
      footer: {
        brand: `${WlBaseData.storageUrlBase}/brand-primary-small.svg`,
        background: {
          color: '#ffffff',
        },
        border: {
          color: WlBaseData.colors.primary,
        },
      },
    },
    timeline: {
      brand: {
        logo: `${WlBaseData.storageUrlBase}/brand-monochrome-small.svg`,
      },
      background: {
        image: `${WlBaseData.storageUrlBase}/background-vertical-large.svg`,
        color: 'transparent',
      },
      text: {
        color: {
          primary: '#ffffff',
          secondary: '#ffffff',
        },
      },
      bullet: {
        color: {
          primary: '#ffffff',
          secondary: WlBaseData.colors.tertiary,
          tertiary: WlBaseData.colors.tertiary,
        },
        border: {
          color: WlBaseData.colors.tertiary,
        },
      },
    },
    aside: {
      brand: {
        logo: `${WlBaseData.storageUrlBase}/brand-monochrome-small.svg`,
      },
      background: {
        color: WlBaseData.colors.primary,
      },
      text: {
        color: '#ffffff',
        hover: '#ffffff',
      },
    },
    header: {
      billet: {
        brand: {
          logo: `${WlBaseData.storageUrlBase}/brand-primary-small.svg`,
        },
      },
      brand: {
        logo: {
          primary: `${WlBaseData.storageUrlBase}/brand-primary-small.svg`,
          secondary: `${WlBaseData.storageUrlBase}/brand-monochrome-small.svg`,
        },
      },
      background: {
        image: `${WlBaseData.storageUrlBase}/background-horizontal-small.svg`,
        color: {
          primary: '#ffffff',
          secondary: WlBaseData.colors.secondary,
        },
      },
      button: {
        background: {
          color: {
            primary: WlBaseData.colors.primary, // general buttons
            secondary: '#ffffff',
          },
        },
        text: {
          color: {
            primary: '#ffffff',
            secondary: WlBaseData.colors.primary,
          },
        },
      },
      text: {
        color: {
          primary: '#ffffff',
          secondary: WlBaseData.colors.primary,
          tertiary: '#000000',
        },
      },
      profile: {
        background: {
          image: `${WlBaseData.storageUrlBase}/background-profile.svg`,
          color: '',
        },
      },
    },
    color: {
      primary: WlBaseData.colors.secondary,
      secondary: WlBaseData.colors.primary,
    },
    font: {
      size: '100%',
      family: '',
    },
    global: {
      button: {
        text: {
          color: '#ffffff',
        },
      },
    },
    storeImage: {
      apple: {
        visible: false,
        link: '',
      },
      google: {
        visible: false,
        link: '',
      },
    },
    images: [
      {
        tag: 'transfer',
        path: './assets/images/cash-in/account-share/transfer.svg',
        name: 'Transfer',
        url: null,
        external: false,
        description: 'Transfer',
      },
      {
        tag: 'confirmated',
        path: 'assets/images/cash-out/payment-notification/confirmado.svg',
        name: 'confirmado',
        url: null,
        external: false,
        description: 'confirmated',
      },
      {
        tag: 'warning-icon',
        path: 'assets/images/cash-out/payment-notification/warn.svg',
        name: 'warn',
        url: null,
        external: false,
        description: 'warning-icon',
      },
      {
        tag: 'info-icon',
        path: 'assets/images/cash-out/payment-notification/info.svg',
        name: 'info',
        url: null,
        external: false,
        description: 'info-icon',
      },
      {
        tag: 'recused',
        path: 'assets/images/cash-out/payment-notification/recusado.svg',
        name: 'recusado',
        url: null,
        external: false,
        description: 'recused',
      },
      {
        tag: 'recused',
        path: 'assets/images/cash-out/payment-notification/recusado.svg',
        name: 'recusado',
        url: null,
        external: false,
        description: 'Recused',
      },
      {
        tag: 'barcode',
        path: 'assets/images/dashboard/barcode-outline.svg',
        name: 'barcode-outline',
        url: null,
        external: false,
        description: 'Recused',
      },
      {
        tag: 'card',
        path: 'assets/images/dashboard/card.svg',
        name: 'card',
        url: null,
        external: false,
        description: 'Card',
      },
      {
        tag: 'earnings',
        path: 'assets/images/dashboard/earnings.svg',
        name: 'earnings',
        url: null,
        external: false,
        description: 'Earnings',
      },
      {
        tag: 'loan',
        path: 'assets/images/dashboard/loan.svg',
        name: 'loan',
        url: null,
        external: false,
        description: 'Loan',
      },
      {
        tag: 'sales',
        path: 'assets/images/dashboard/sales.svg',
        name: 'sales',
        url: null,
        external: false,
        description: 'Sales',
      },
      {
        tag: 'swap',
        path: 'assets/images/dashboard/swap.svg',
        name: 'swap',
        url: null,
        external: false,
        description: 'Swap',
      },
      {
        tag: 'device',
        path: 'assets/images/device-validation/device.svg',
        name: 'device',
        url: null,
        external: false,
        description: 'Device',
      },
      {
        tag: 'send-code',
        path: 'assets/images/device-validation/send-code.svg',
        name: 'send-code',
        url: null,
        external: false,
        description: 'Send Code',
      },
      {
        tag: 'avatar',
        path: 'assets/images/header-web/avatar.svg',
        name: 'avatar',
        url: null,
        external: false,
        description: 'Avatar',
      },
      {
        tag: 'payment',
        path: 'assets/images/notifications/grid/payment.svg',
        name: 'payment',
        url: null,
        external: false,
        description: 'Payment',
      },
      {
        tag: 'supermarket',
        path: 'assets/images/notifications/grid/supermarket.svg',
        name: 'supermaket',
        url: null,
        external: false,
        description: 'Supermarket',
      },
      {
        tag: 'analise-concluded',
        path: 'assets/images/notifications/status-account/concluido-analise.svg',
        name: 'concluido-analise',
        url: null,
        external: false,
        description: 'Analise Concluded',
      },
      {
        tag: 'devolved-document',
        path: 'assets/images/notifications/status-account/documento-devolvido.svg',
        name: 'documento-devolvido',
        url: null,
        external: false,
        description: 'Devolved Document',
      },
      {
        tag: 'success',
        path: 'assets/images/notifications/status-response/success.svg',
        name: 'success',
        url: null,
        external: false,
        description: 'Success',
      },
      {
        tag: 'success',
        path: 'assets/images/notifications/status-response/success.svg',
        name: 'success',
        url: null,
        external: false,
        description: 'Success',
      },
      {
        tag: 'billet',
        path: 'assets/images/onboarding-ec/billet-icon.svg',
        name: 'billet-icon',
        url: null,
        external: false,
        description: 'Billet',
      },
      {
        tag: 'card-machine',
        path: 'assets/images/onboarding-ec/card-machine-icon.svg',
        name: 'card-machine-icon',
        url: null,
        external: false,
        description: 'Card Machine',
      },
      {
        tag: 'credit-card',
        path: 'assets/images/onboarding-ec/credit-card-icon.svg',
        name: 'credit-card-icon',
        url: null,
        external: false,
        description: 'Credit Card',
      },
      {
        tag: 'energy',
        path: 'assets/images/onboarding-ec/energy-icon.svg',
        name: 'energy-icon',
        url: null,
        external: false,
        description: 'Energy',
      },
      {
        tag: 'camera',
        path: 'assets/images/onboarding-ec/icone foto.svg',
        name: 'icone foto',
        url: null,
        external: false,
        description: 'Camera',
      },
      {
        tag: 'gallery',
        path: 'assets/images/onboarding-ec/icone galeria.svg',
        name: 'icone galeria',
        url: null,
        external: false,
        description: 'Gallery',
      },
      {
        tag: 'credit-card',
        path: 'assets/images/onboarding-ec/credit-card-icon.svg',
        name: 'credit-card-icon',
        url: null,
        external: false,
        description: 'Credit Card',
      },
      {
        tag: 'showcase',
        path: 'assets/images/onboarding-ec/tela 1 vitrine.svg',
        name: 'tela 1 vitrine',
        url: null,
        external: false,
        description: 'Tela 1 Vitrine',
      },
      {
        tag: 'showcase',
        path: 'assets/images/onboarding-ec/tela 2 vitrine.svg',
        name: 'tela 2 vitrine',
        url: null,
        external: false,
        description: 'Tela 2 Vitrine',
      },
      {
        tag: 'showcase',
        path: 'assets/images/onboarding-ec/tela 3 vitrine.svg',
        name: 'tela 3 vitrine',
        url: null,
        external: false,
        description: 'Tela 3 Vitrine',
      },
      {
        tag: 'legal-person',
        path: 'assets/images/sign-up/account-type/legalPerson.svg',
        name: 'legalPerson',
        url: null,
        external: false,
        description: 'Legal Person',
      },
      {
        tag: 'mei',
        path: 'assets/images/sign-up/account-type/mei.svg',
        name: 'mei',
        url: null,
        external: false,
        description: 'MEI',
      },
      {
        tag: 'mei-white',
        path: 'assets/images/sign-up/account-type/mei white.svg',
        name: 'mei white',
        url: null,
        external: false,
        description: 'MEI White',
      },
      {
        tag: 'physical-person',
        path: 'assets/images/sign-up/account-type/physicalPerson.svg',
        name: 'physicalPerson',
        url: null,
        external: false,
        description: 'Physical Person',
      },
      {
        tag: 'self-employed',
        path: 'assets/images/sign-up/account-type/selfEmployed.svg',
        name: 'selfEmployed',
        url: null,
        external: false,
        description: 'Self Employed',
      },
      {
        tag: 'accept-mei',
        path: 'assets/images/sign-up/choose-document/acceptMei.svg',
        name: 'acceptMei',
        url: null,
        external: false,
        description: 'Accept MEI',
      },
      {
        tag: 'meeting',
        path: 'assets/images/sign-up/choose-document/ata.svg',
        name: 'ata',
        url: null,
        external: false,
        description: 'Minutes of the Meeting',
      },
      {
        tag: 'choose-document',
        path: 'assets/images/sign-up/choose-document/choose-document.svg',
        name: 'choose-document',
        url: null,
        external: false,
        description: 'Choose Document',
      },
      {
        tag: 'cnh',
        path: 'assets/images/sign-up/choose-document/cnh.svg',
        name: 'cnh',
        url: null,
        external: false,
        description: 'CNH',
      },
      {
        tag: 'cnpj',
        path: 'assets/images/sign-up/choose-document/cnpj.svg',
        name: 'cnpj',
        url: null,
        external: false,
        description: 'CNPJ',
      },
      {
        tag: 'contract',
        path: 'assets/images/sign-up/choose-document/contract.svg',
        name: 'contract',
        url: null,
        external: false,
        description: 'Contract',
      },
      {
        tag: 'university-degree',
        path: 'assets/images/sign-up/choose-document/diploma.svg',
        name: 'diploma',
        url: null,
        external: false,
        description: 'University Degree',
      },
      {
        tag: 'procuration',
        path: 'assets/images/sign-up/choose-document/procuration.svg',
        name: 'procuration',
        url: null,
        external: false,
        description: 'Procuration',
      },
      {
        tag: 'proof-address',
        path: 'assets/images/sign-up/choose-document/acceptMei.svg',
        name: 'acceptMei',
        url: null,
        external: false,
        description: 'Proof Address',
      },
      {
        tag: 'rg',
        path: 'assets/images/sign-up/choose-document/RG-icon2.svg',
        name: 'RG-icon2',
        url: null,
        external: false,
        description: 'RG',
      },
      {
        tag: 'selfie',
        path: 'assets/images/sign-up/choose-document/selfie.svg',
        name: 'selfie',
        url: null,
        external: false,
        description: 'Selfie',
      },
      {
        tag: 'adrress-pin',
        path: 'assets/images/sign-up/document-type/pin.svg',
        name: 'pin',
        url: null,
        external: false,
        description: 'Address Pin',
      },
      {
        tag: 'upload-concluded',
        path: 'assets/images/sign-up/upload-document/concluido-upload.svg',
        name: 'concluido-upload',
        url: null,
        external: false,
        description: 'Upload Concluded',
      },
      {
        tag: 'jpg',
        path: 'assets/images/sign-up/upload-document/jpg-image.svg',
        name: 'jpg-image',
        url: null,
        external: false,
        description: 'JPG',
      },
      {
        tag: 'pdf',
        path: 'assets/images/sign-up/upload-document/pdf-image.svg',
        name: 'pdf-image',
        url: null,
        external: false,
        description: 'PDF',
      },
      {
        tag: 'upload-document',
        path: 'assets/images/sign-up/upload-document/upload-document.svg',
        name: 'upload-document',
        url: null,
        external: false,
        description: 'Upload Document',
      },
      {
        tag: 'warning',
        path: 'assets/images/sign-up/warning-alert/warning.svg',
        name: 'warning-alert',
        url: null,
        external: false,
        description: 'Warning Alert',
      },
      {
        tag: 'mastercard-flag',
        path: 'assets/images/card-manager/icn-mastercard.svg',
        name: 'mastercard-flag',
        url: null,
        external: false,
        description: 'Mastercard Flag',
      },
      {
        tag: 'email-send',
        path: 'assets/images/card-manager/email-send.svg',
        name: 'email-send',
        url: null,
        external: false,
        description: 'email-send',
      },
      {
        tag: 'card-request',
        path: 'assets/images/card-manager/card-request.svg',
        name: 'card-request',
        url: null,
        external: false,
        description: 'Card Request',
      },
      {
        tag: 'solicitation-card',
        path: 'assets/images/cards/cardSolicitation.svg',
        name: 'solicitation-card',
        url: null,
        external: false,
        description: 'Solicitation Card',
      },
      {
        tag: 'solicitation-sucess-card',
        path: 'assets/images/cards/cardSolicitationSucess.svg',
        name: 'solicitation-sucess-card',
        url: null,
        external: false,
        description: 'Solicitation Sucess Card',
      },
      {
        tag: 'pix-tema',
        path: 'assets/images/pix/pix.svg',
        name: 'pix-tema',
        url: null,
        external: false,
        description: 'Icone Pix Tema',
      },
      {
        tag: 'pix-preto',
        path: 'assets/images/pix/simbolo_pix_preto.svg',
        name: 'pix-preto',
        url: null,
        external: false,
        description: 'Icone Pix Preto',
      },
      {
        tag: 'pix-padrao',
        path: 'assets/images/pix/simbolo_pix_policromia_positivo.svg',
        name: 'pix-padrao',
        url: null,
        external: false,
        description: 'Icone Pix Padrao',
      },
      {
        tag: 'portability-keys',
        path: 'assets/images/pix/portability-keys.svg',
        name: 'portability-keys',
        url: null,
        external: false,
        description: 'Portability Keys',
      },
      {
        tag: 'claim-keys',
        path: 'assets/images/pix/claim-keys.svg',
        name: 'claim-keys',
        url: null,
        external: false,
        description: 'Claim Keys',
      },
      {
        tag: 'showcase-pix-first',
        path: 'assets/images/showcase-pix/ilustracao-1.svg',
        name: 'key-management',
        url: null,
        external: false,
        description: 'Key Management',
      },
      {
        tag: 'showcase-pix-second',
        path: 'assets/images/showcase-pix/ilustracao-2.svg',
        name: 'key-management',
        url: null,
        external: false,
        description: 'Key Management',
      },
      {
        tag: 'showcase-pix-third',
        path: 'assets/images/showcase-pix/ilustracao-3.svg',
        name: 'key-management',
        url: null,
        external: false,
        description: 'Key Management',
      },
      {
        tag: 'returns-pix',
        path: 'assets/images/pix/devolucoes.svg',
        name: 'returns-pix',
        url: null,
        external: false,
        description: 'Returns-pix',
      },
      {
        tag: 'extract-pix',
        path: 'assets/images/pix/extrato.svg',
        name: 'extract-pix',
        url: null,
        external: false,
        description: 'Extract-pix',
      },
      {
        tag: 'pay-pix',
        path: 'assets/images/pix/pagar-pix.svg',
        name: 'pay-pix',
        url: null,
        external: false,
        description: 'Pay-pix',
      },
      {
        tag: 'manual-pix',
        path: 'assets/images/pix/pix-manual.svg',
        name: 'manual-pix',
        url: null,
        external: false,
        description: 'Manual-pix',
      },
      {
        tag: 'qr-code-pix',
        path: 'assets/images/pix/pix-qr-code.svg',
        name: 'qr-code-pix',
        url: null,
        external: false,
        description: 'Qr-code-pix',
      },
      {
        tag: 'receive-pix',
        path: 'assets/images/pix/receber-pix.svg',
        name: 'receive-pix',
        url: null,
        external: false,
        description: 'Receive-pix',
      },
      {
        tag: 'cpf-cnpj-pix',
        path: 'assets/images/pix/cpf-cnpj.svg',
        name: 'cpf-cnpj-pix',
        url: null,
        external: false,
        description: 'CPF-CNPJ-pix',
      },
      {
        tag: 'email-pix',
        path: 'assets/images/pix/email.svg',
        name: 'email-pix',
        url: null,
        external: false,
        description: 'Email-pix',
      },
      {
        tag: 'virtual-address-pix',
        path: 'assets/images/pix/virtual-address.svg',
        name: 'virtual-address-pix',
        url: null,
        external: false,
        description: 'Virtual-address-pix',
      },
      {
        tag: 'phone-pix',
        path: 'assets/images/pix/phone.svg',
        name: 'phone-pix',
        url: null,
        external: false,
        description: 'Phone-pix',
      },
      {
        tag: 'share-pix',
        path: 'assets/images/pix/compartilhar.svg',
        name: 'share-pix',
        url: null,
        external: false,
        description: 'share-pix',
      },
      {
        tag: 'copy-pix',
        path: 'assets/images/pix/copiar-link.svg',
        name: 'copy-pix',
        url: null,
        external: false,
        description: 'copy-pix',
      },
      {
        tag: 'warning-circle',
        path: 'assets/images/pix/warning-circle.svg',
        name: 'warning-circle-alert',
        url: null,
        external: false,
        description: 'Warning Circle Alert',
      },
      {
        tag: 'portability-keys',
        path: 'assets/images/pix/portability-keys.svg',
        name: 'portability-keys',
        url: null,
        external: false,
        description: 'Portability Keys',
      },
      {
        tag: 'claim-keys',
        path: 'assets/images/pix/claim-keys.svg',
        name: 'claim-keys',
        url: null,
        external: false,
        description: 'Claim Keys',
      },
    ],
  },
};

// ADD DYNAMIC IMAGES
for (let i = 1; i <= WlBaseData.introImages; i++) {
  wlThemeParameterization.wlTheme.images.push({
    tag: 'intro',
    path: `${WlBaseData.storageUrlBase}/slide-small-${i}.svg`,
    name: 'Slide Intro',
    url: null,
    external: false,
    description: 'Slide Intro',
  });
}

for (let i = 1; i <= WlBaseData.bannerFullImages; i++) {
  wlThemeParameterization.wlTheme.images.push({
    tag: 'swiper-full',
    path: `${WlBaseData.storageUrlBase}/banner-full-${i}.png`,
    name: 'Swiper',
    url: null,
    external: false,
    description: 'Swipper',
  });
}

for (let i = 1; i <= WlBaseData.bannerSquareImages; i++) {
  wlThemeParameterization.wlTheme.images.push({
    tag: 'swiper-square',
    path: `${WlBaseData.storageUrlBase}/banner-square-${i}.png`,
    name: 'Swiper',
    url: null,
    external: false,
    description: 'Swipper',
  });
}

export const environment = {

  production: true,
  wlp: WlBaseData.uuidWl, // White label com todos os fluxos
  apiUri: 'https://api.secure.srv.br',
  saltTransaction: 'kOR3/YwovgpJnKaiHkOoBpOdedtm8qUALg/tybkWyBM=',
  publicKeyApp: '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAp9iRp56HhDvdlu1eQeys bv/kpCFCPTqHgGvFdVuub85RBEKgSTtqgdltJ+qKvY7uaWOIsLZKs36osqf38hNv aXJccW5xBv1VFPtgdjGTRt9FHyo0JFUvxpUcm7BYCY5aWMelvtC/mS/Zo2BFQYex A5mjoh2u+F0eifDYme7f+/VydkbftS0OHJdwVlbTggy3PSIYXWI/g/GjHNeU6XOg rDcG0pAQ+aN9a+HajtgQ1kQ/T41sbacX12dr7tyqa450ysw45B2uSo+3IRHLf52V PkJjQfNLUG0Oe+F8T5UGnPwOQR+/QvbjL9k7AnOB5u7KdZZOLKGXHVuVN5konfVL /QIDAQAB\n-----END PUBLIC KEY-----',
  keyForm: 'keyForm',
  typeUpload: 'typeUpload',

  authTokenKey: '',
  keyAccount: null,
  keycloakConfig,
  wlThemeParameterization,
  appVersion: require('../../package.json').version,
};
